/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//
export default {
  'fabric.linkPicker.button.cancel': 'Cancel',
  'fabric.linkPicker.button.insert': 'Insert',
  'fabric.linkPicker.button.save': 'Save',
  'fabric.linkPicker.clearLink': 'Clear',
  'fabric.linkPicker.clearLinkText': 'Clear text',
  'fabric.linkPicker.hyperlink.searchLinkResults':
    '{count, plural, =0 {no results} one {# result} other {# results}} found',
  'fabric.linkPicker.linkAriaLabel': 'Type or paste a link into the field',
  'fabric.linkPicker.linkHelperTextAriaLabel': 'Link display helper text',
  'fabric.linkPicker.linkHelperTextLabel': 'Give this link a title or description',
  'fabric.linkPicker.linkInvalid': 'Enter a valid URL.',
  'fabric.linkPicker.linkLabel': 'Link',
  'fabric.linkPicker.linkPlaceholder': 'Paste a link',
  'fabric.linkPicker.linkTextAriaLabel': 'Link display text',
  'fabric.linkPicker.linkTextLabel': 'Display text (optional)',
  'fabric.linkPicker.linkTextPlaceholder': 'Text to display',
  'fabric.linkPicker.listTitle.recentlyViewed': 'Recently Viewed',
  'fabric.linkPicker.listTitle.results': 'Results',
  'fabric.linkPicker.search.error.description':
    'Refresh the page, or contact <a>Atlassian Support</a> if this keeps happening.',
  'fabric.linkPicker.search.error.heading': 'We’re having trouble loading data.',
  'fabric.linkPicker.search.error.retry': 'Refresh',
  'fabric.linkPicker.search.linkAriaLabel': 'Suggestions will appear below as you type into the field',
  'fabric.linkPicker.search.linkLabel': 'Search or paste a link',
  'fabric.linkPicker.search.linkPlaceholder': 'Find recent links or paste a new link',
  'fabric.linkPicker.search.noResults.description': 'Try again with a different term.',
  'fabric.linkPicker.search.noResults.heading': 'We couldn’t find anything matching your search.',
  'fabric.linkPicker.status.submitting': 'Submitting',
  'fabric.linkPicker.time.message.updated': 'Updated {time}',
  'fabric.linkPicker.time.message.viewed': 'Viewed {time}',
  'fabric.linkPicker.unhandledError.description': 'Try reloading the page.',
  'fabric.linkPicker.unhandledError.heading': 'Something went wrong!',
  foo: 'Default string',
};
